@tailwind base;
@tailwind components;
@tailwind utilities;

/*@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=DM+Sans&display=swap');*/

/*@font-face {*/
/*    font-family: 'Helvetica Neue';*/
/*    src: url('./fonts/HelveticaNeue.ttc')*/
/*}*/

/*body {*/
/*    font-family:  'Helvetica Neue';*/
/*}*/
html {
    scroll-behavior: smooth;
}

.font-bebas {
    font-family: 'Bebas Neue', cursive;
}

.font-dm {
    font-family: 'DM Sans', sans-serif;
}

.subscribeContainer {
    background: linear-gradient(120deg, rgba(249, 217, 109, 1) 55%, rgba(246, 193, 13, 1) 45%);
}

.content {
    overflow-x: hidden;
    margin: 0 auto;
    padding-top: 10px;
}

.slick-slide > div {
    margin: 0 40px;
    padding-top: 30px;

}
.slick-list {
    margin: 0 -40px;
    overflow-x: hidden !important;
    height: 300px;
}

.reviewContainer {
    background:  linear-gradient(90deg, rgba(246,246,246,1) 0%, rgba(255,255,255,1) 100%);
}

@media only screen and (max-width: 450px) {
    .reviewContainer {
        width: 250px !important;
    }
    .productContainer {
        width: 230px !important;
    }
}

@media only screen and (min-width: 360px) {
    .productContainer {
        width: 250px !important;
    }
}


@media only screen and (min-width: 451px) {
    .reviewContainer {
        width: 300px !important;
    }
    .productContainer {
        width: 300px !important;
    }
}

@media only screen and (min-width: 600px) {
    .productContainer {
        width: 370px !important;
        padding-top: 2rem;
        padding-bottom: 2rem;
    }
}

@media only screen and (min-width: 768px) {
    .productContainer {
        width: 450px !important;

    }
}



@media only screen and (min-width: 1024px) {
    .reviewContainer {
        width: 400px !important;
    }
    .productContainer {
        width: 400px !important;
    }
}


.slick-slider {
    height: 100%;
    width: 100%;
    background-color: transparent;

}

 .slick-current {
    transform: scale(1.2) !important;
     /*transition: all 0.3s ease !important;*/
     padding-top: 10px;

}

 .slick-track {
     height: 300px;
 }

 .animate-bounce {
     animation: bounce 1s infinite;
 }

@keyframes bounce {
    0%, 100% {
        transform: translateY(-25%);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
        transform: translateY(0);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}

.inset-center {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
}


.slick-track {
    display: flex !important;
}
.slick-track .slick-slide {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
}



